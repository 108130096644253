

.gradient_background{
    background: url('../../images/fondo_consulta.jpg') no-repeat center top;
}

.consulta_texto_cedula{
    font-size: medium;
}
.consulta_container {
    display: flex;
    justify-content:space-between;
    flex-direction: row;
}

.consulta_slider {   
    border-top: none !important;
    width: 50% !important;
}

.consulta_linea_atencion {
    color: rgba(23, 98, 134, 255);
    font-weight: bold;
    font-size: larger;
}

.consulta_texto_informativo {
    margin-top: 1.5rem;
    color: rgba(203, 203, 203, 255);
}

.consulta_container_client {
    border-radius: 1rem;
    margin: 3rem;
    /* margin-top: calc(10% - 100px); */
    width: 45%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.consulta_container_client_content {
    width: 70%;
    margin: 0rem!important;
    background: #FFFFFF 0% 0% no-repeat padding-box;
}

.consulta_form {
    min-height: 150px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
}
.carrusel-mobile{
    display: none;
}
@media (max-width:1400px) {
    
    .consulta_container_client_content{
        width: 80%;
        margin-bottom: 3rem!important;
    }

    .consulta_slider{
        width: 60%!important;
    }

    .consulta_container_client{
        margin: 0rem;
        margin-top: 3rem;
    }
}

@media (max-width:580px) {
    .carrusel-mobile{
		display: contents;
	}

}


@media (max-width:1200px) {
    .consulta_container {
        flex-direction: column;
        justify-content: space-between;
    }

    .consulta_container_client {
        /* margin-top: 1rem; */
        width: 100%;
    }


    .consulta_slider{
        width: 100%;
    }

    .carrusel-pc{
		display: none;
	}
	
}

.consulta_boton {
    background-color: rgba(0, 82, 122, 255) !important;
    border: none;
}

.consulta_boton:disabled {
    background-color: gray !important;
}

.consulta_icono_ws {
    color: #00ad3a;
    background-color: #00ad3a;
    border-radius: 10px;
    padding: 4px;
}


