.float{
	position:fixed;
	width:50px;
	height:50px;
	bottom:50%;
	right:20px;
	background-color:#f8f8f8;
	color:#25d366;
	border-radius:10px;
	text-align:center;
  	font-size:30px;
	box-shadow: 2px 2px 3px #999;
  	z-index:100;
}
.float:hover {
	text-decoration: none;
	color: #ffffff;
  background-color:#25d366;
}

.my-float{
	margin-top:12px;
}

@media (max-width:420px) {

	.float{
		bottom:10px;
		right:10px;
	}

}

.flex-container{
	display: flex;
	margin-bottom: 1em;
	justify-content: center;
}
.flex-item{
	width: auto;
	margin:0px 4px;
	height: 2em;
	text-align: center;
}
.flex-item:hover{
	cursor: pointer;
	
}


.tab-container{
	margin-top: 15px;
}

.tab-body{
	width: 100%;
	border-top:2px  solid #ffe600;
	padding:10px;
}

.non-button{
	background: none;
	color: inherit;
	border: none;
	padding: 0px 1em;
	font: inherit;
	cursor: pointer;
	outline: inherit;
	margin: 0;
}

.item-selected{
	color: #f50a0a;
	border-bottom:2px  solid #f50a0a;
}

.flex-container-mobile{
	display: none;
}

@media (max-width:768px) {
	.item-selected{
		height:50px	
	}
}

@media (max-width:420px) {

	.flex-container{
		display: none;
	}
	.flex-container-mobile{
		display: flex;
		justify-content: space-between;
		flex-direction: column;
		margin:0.5em;
		border: 2px solid #c9c4c4;
		border-radius:10px;
		padding:2em 1em 3em 1em;
		color: 2px solid #c9c4c4;
	}

	.flex-container-mobile a{
		text-align: center
	}

	.button-container-mobile{
		padding:0.5em;
		margin:0em 3em;
		border-bottom:2px  solid #c9c4c4;
		color: #c9c4c4;
		font-size: 18px;
	}

	.flex-button-selected{
		color: #f50a0a;
	}

	.tab-body{
		padding-top:8em
	}
	
}
