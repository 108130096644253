h1, h2, h3, h4, h5, h6,a ,p {
    font-family: 'Quicksand', sans-serif;
    color: #2d2d2d;
    font-weight: 400;
}

.body-container{
    margin-top: 9em;
}

.font-arial{
    font-family: 'Arial', Regular;
}

.nav-container{
    position: fixed;
    top:0;
    width: 100%;
    margin-bottom: 1em;
    z-index: 150;
}


.nav-flex{
    background-color:#ffe600;
    width: 100%;
    padding: 0em 1em;
   
}

.nav-flex-container{
    display: flex;
    justify-content: space-between;
    height: auto;
    padding:0 10em;
    
}

.full-fill{
    height: 77px;
}

.nav-flex-logo{
    width: 7em ;
    height: 7em;
    border-radius:50%
}

.nav-iniciar-sesion{
  
    display: flex;
    justify-content: center;
}

.nav-logo-sesion{
    width: 5em;
    height: 5em;
    border-radius:50%
}

.align-vertical{
    vertical-align: middle;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.nav-texto-sesion{
    padding-left: 1em;
}

.nav-texto-sesion p{
    
    color: #ff0000;
    font-size:600;
}

.nav-flex-container-menu{
    display: flex;
    height: auto;
    background-color:#ffffff;
    width: 100%;
    padding: 1em;

}

.nav-flex-menu{
    padding-left: 1.2em;
}

.nav-flex-menu button{
    color: #2d2d2d;
    text-decoration: none;
    font-size:1.5em;
}

.checkbtn{
   font-size: 1em;
   color:#ffffff;
   float: left;
   display:none;
   
}

#checkbox{
    display:none;
}


.nav-flex-submenu{
    display: flex;
    border: 1px solid #d4d4d4;
    border-radius: 20px;
    background-color:rgba(255, 255, 255, 0.95);
    position: absolute;
    z-index: 100;
    margin-left: -1em;
    opacity:0;
    visibility: hidden;
}

.nav-flex-box{
    margin: 0;
    padding: 3em 3em;
}

.nav-flex-box ul{
   padding: 0;
   margin: 0;
   align-items: center;
}

.nav-flex-menu button:hover + .nav-flex-submenu {
    opacity: 1;
    visibility: visible;
    color: #fff;
}

.nav-flex-submenu:hover{
    opacity: 1;
    visibility: visible;
}

/* 
.nav-flex-submenu ul {
    margin: 0;
    padding: 3em 3em;
} */
.nav-flex-submenu ul li{
    list-style: none;
    padding: 0.3em 0em; 
}
.nav-flex-submenu  h3{
    color: #ff0000 ;
    font-weight: bold ;
}
.nav-flex-submenu ul li a{
    text-decoration: none;
    color:#2d2d2d;
    font-weight: bold ;
    font-size:1em

}

.nav-flex-submenu ul li a:hover{
    cursor: pointer;
    color:#ff0000 ;
    transition: 0.2s
}


@media (max-width:750px) {
    
    .nav-flex-container{
        padding:0 2em; 
   } 
   .nav-flex-submenu{
    flex-direction: column;
    justify-content: center;
   }

}

@media (max-width:420px) {

    
    .full-fill{
        height: 2em;
    }

    .hide{
        display: none;
    }
    
    .checkbtn{
        background: none;
        color: inherit;
        border: none;
        padding: 0px 1em;
        cursor: pointer;
        outline: inherit;
        margin: 0;

        display:block; 
        font-family: 'Quicksand', sans-serif;
        font-size:2em     
    }

    .nav-iniciar-sesion {
        position:absolute;
        z-index: 120;
        margin-top:7.5em;
        padding-left: 5%;
    }
    
    
    .nav-flex-container{
        padding:0;
        width:100%;
        flex-direction: column;
        justify-content: center;
    }   

   .nav-flex-main{
        flex-direction: row;
        justify-content: space-between;
   }

    .nav-flex-responsive{
        padding-top:4em;
        border: 1px solid #d4d4d4;
        background-color:rgba(255, 255, 255, 0.7);
        padding-bottom: 2em;

    }
    

    .nav-flex-container-menu{
        margin: 0;
        padding:0;
        position: absolute;
        background-color:rgba(255, 255, 255, 0.7);
        z-index:100
    }


    .nav-flex-menu button{
        margin-left: 1em;
        margin-bottom: 0.5em;
        padding-bottom: 0.5em;
        padding-top: 1em;
        font-size:2.1em;
        border-bottom: 2px solid #ff0000;
    }

    .nav-flex-box{
        padding: 0em;
        margin: 0em;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .nav-flex-box button{
        margin: 0.1em 0em;
        padding: 0;
        align-items: center;
        padding: 0em 1em; 
    }

    

    .nav-flex-options{
        position: inherit;
        margin:0.5em 0em 0em 0em;
        padding: 0.5em 0.5em;
        flex-direction: column;
        justify-content: center;
        width:100%;
        border: none;
        background-color:rgba(0, 0, 0, 0);
        display: none;
    }
    .nav-flex-options ul li{
        text-align:center;
        margin-left:-1em;
        padding:0em;
    }
  

    .nav-flex-submenu{
        pointer-events: all;
        position: inherit;
        z-index: -100;
        margin:0.5em 0em 0em 0em;
        padding:0;
        flex-direction: column;
        justify-content: center;
        width:100%;
        border: none;
        background-color:rgba(0, 0, 0, 0);
        display: none;
        align-items: center;
    }


  
    .show-submenu{
        display:flex;
    }

    

    .nav-flex-submenu ul{
        padding:0.2em 0em;
    }

    .nav-flex-submenu ul h3{
        text-align: center;
        border-bottom: 2px solid #ff0000;
        font-size:1.8em;
        margin: 0.2em 2em
    }
    .nav-flex-submenu ul li{
        text-align: left;
        padding: 1em 0em; 
        /* padding-left: 24%; */
    }
    .nav-flex-submenu ul li a{
        padding:0;
        border:none;
        font-size:1.5em;
    }
    

}


/* Aliados */

.container-aliados{
    margin: 2em 3em;
    display: flex;
    align-items: center;
    justify-content: center;
}

.aliados-title{
    font-size:0.9em;
    width: 10%;
}

.aliados-container-logos{
    display: flex;
    align-items: center;
    justify-content: space-between;

}

.aliados-logo{
    width:7.5em;
    /* height: 7.5em; */
    /* border-radius: 50%;
    border: 3px solid #ffe600; */
    margin: 1em 1em;
    /* padding: 0.5em; */
    display: flex;
    align-items: center;
    justify-content:center;
}

.aliados-logo .ck-content{
    margin:0;
    align-items: center;
}


/*Declared in PublicStyles/_media-queries.scss as well, in case it does not change*/
@media (max-width:605px) {
    .aliados-title{
        display: none;
    }

    .aliados-logo{
        width: 5em;
        /* height: 5em; */
    }
}


/* Landing */

.container-landing{
    display: flex;
    justify-content: center;
    width: auto;
    margin: 3em 3em;
}

.landing-item{
    width: 15em;
    /* height: 5.4em; */
    /* border: 1px solid #ff0000; */
    /* border-radius: 10px; */
    margin: 0em 0.5em;
    display: flex;
    align-items: center;
    justify-content:center;
}

@media (max-width:420px) {

    .landing-item{
        width: 14em;
        /* height: 4.4em; */
        
    }

}


/*Footer*/

.footer-background{
    position: relative;
    background-color:#ffe600;
    width: 100%;
    bottom: 0;
    left: 0;
    height:auto;
    padding: 0.5em;
}

.footer-gray{
    position: relative;
    background-color:#e9e9e9;
    width: 100%;
    bottom: 0;
    left: 0;
    height:auto;
    padding: 0.5em;
}

.footer-white{
    position: relative;
    background-color:#ffffff;
    width: 100%;
    bottom: 0;
    left: 0;
    height:auto;
    padding: 0.5em;
}


.footer-container{
    margin: 3% 14% 1% 14% ;
    display:flex;
    flex-direction:column;
}

.footer-section-main{
    display:flex;
    justify-content:space-between;
}

.footer-item-main{
    width:auto;
    height:auto;
}

.footer-section{
    margin:2em 3em;
    display: flex;
    /* flex-direction:column;
    align-items: center; */
    justify-content:center;
}

/*Extra Iconos redes sociales*/

.footer-social-share .socialShare li {
    display: inline-block;
    margin: 0 5px; }

.footer-social-share .socialShare li a {
background: url("../../images/social-sprite.png") no-repeat 0 0;
background-size: 189px 47px;
display: inline-block;
text-indent: -999px;
overflow: hidden;
align-self: center;
width: 48px;
height: 48px; 
}
.footer-social-share .socialShare li a:hover {
opacity: 0.6; }
.footer-social-share .socialShare li a.twitterIcon {
background-position: 1px 0; }
.footer-social-share .socialShare li a.facebookIcon {
background-position: -47px 0; }
.footer-social-share .socialShare li a.instagramIcon {
background-position: -94.3px 0; }
.footer-social-share .socialShare li a.youtubeIcon {
background-position: -142px 0; }




@media (max-width:750px) {
    .footer-container{
        margin: 3% 8% 1% 8% ;
        
    }
    
}
@media (max-width:420px) {
    .footer-container{
        margin: 3% 8% 1% 8% ;
       
    }
    .footer-section-main{
        flex-direction: column;
    }
    .footer-section{
        flex-direction: column;
        margin:2em;
    }

}